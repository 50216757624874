var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "filtro-content filtro" },
    [
      _c(
        "vs-button",
        {
          staticClass: "max-w-lg",
          attrs: {
            color: "primary",
            type: "border",
            icon: "keyboard_arrow_down",
            "icon-after": true,
          },
          on: {
            click: function ($event) {
              _vm.filter = true
            },
          },
        },
        [_vm._v("\n    Exportar Dados\n  ")]
      ),
      _c(
        "vs-popup",
        {
          attrs: { title: "", active: _vm.filter, id: "filtro-open" },
          on: {
            "update:active": function ($event) {
              _vm.filter = $event
            },
          },
        },
        [
          !_vm.resultado
            ? _c(
                "div",
                { staticClass: "item data" },
                [
                  _c("h2", [
                    _vm._v(
                      "Relatório de " + _vm._s(_vm.Label) + " Cadastrados"
                    ),
                  ]),
                  _c("br"),
                  _c("h6", [_vm._v("Selecione o período de cadastro")]),
                  _c("el-date-picker", {
                    attrs: {
                      format: "dd/MM/yyyy",
                      "value-format": "yyyy/MM/dd",
                      type: "daterange",
                      align: "right",
                      "unlink-panels": "",
                      "start-placeholder": "Data Inicial",
                      "end-placeholder": "Data Final",
                      "picker-options": _vm.pickerOptions,
                    },
                    model: {
                      value: _vm.paramModalDate,
                      callback: function ($$v) {
                        _vm.paramModalDate = $$v
                      },
                      expression: "paramModalDate",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "text-center mt-10" },
                    [
                      _vm.loading
                        ? _c(
                            "vs-button",
                            {
                              staticClass: "max-w-lg",
                              attrs: {
                                color: "primary",
                                disabled: !_vm.paramModalDate,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.gerarRelatorio()
                                },
                              },
                            },
                            [_vm._v("\n          Gerar Relatório\n        ")]
                          )
                        : _c(
                            "el-button",
                            { attrs: { type: "primary", loading: true } },
                            [_vm._v("Loading")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "resultado text-center",
              style: _vm.resultado ? "display:block" : "display:none",
            },
            [
              _c("b", [_vm._v("Os dados estão prontos para baixar!")]),
              _c("br"),
              _c("p", [_vm._v("Formato do arquivo: XLSX")]),
              _c("br"),
              _c(
                "div",
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "max-w-lg",
                      attrs: { color: "primary", type: "border" },
                      on: {
                        click: function ($event) {
                          _vm.resultado = false
                          _vm.paramModalDate = false
                        },
                      },
                    },
                    [_vm._v("\n          Cancelar\n        ")]
                  ),
                  _c("a", { attrs: { href: "#", id: "btn" } }, [
                    _vm._v("Exportar dados"),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }