<template>
  <div class="filtro-content filtro">
    <vs-button
      color="primary"
      type="border"
      class="max-w-lg"
      icon="keyboard_arrow_down"
      :icon-after="true"
      @click="filter = true"
    >
      Exportar Dados
    </vs-button>
    <vs-popup :title="''" :active.sync="filter" id="filtro-open">
      <div class="item data" v-if="!resultado">
        <h2>Relatório de {{ Label }} Cadastrados</h2>
        <br />
        <h6>Selecione o período de cadastro</h6>
        <el-date-picker
          v-model="paramModalDate"
          format="dd/MM/yyyy"
          value-format="yyyy/MM/dd"
          type="daterange"
          align="right"
          unlink-panels
          start-placeholder="Data Inicial"
          end-placeholder="Data Final"
          :picker-options="pickerOptions"        
        >
        </el-date-picker>
        <div class="text-center mt-10">
          <vs-button
            v-if="loading"
            color="primary"
            class="max-w-lg"
            :disabled="!paramModalDate"
            @click="gerarRelatorio()"
          >
            Gerar Relatório
          </vs-button>

          <el-button v-else type="primary" :loading="true">Loading</el-button>
        </div>
      </div>
      <div
        class="resultado text-center"
        :style="resultado ? 'display:block' : 'display:none'"
      >
        <b>Os dados estão prontos para baixar!</b><br />
        <p>Formato do arquivo: XLSX</p>
        <br />
        <div>
          <vs-button
            color="primary"
            type="border"
            class="max-w-lg"
            @click="
              resultado = false;
              paramModalDate = false;
            "
          >
            Cancelar
          </vs-button>
          <a href="#" id="btn">Exportar dados</a>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import axiosInstance from "@/axios";
import { Base64 } from "js-base64";
export default {
  name: "Exportar_Dados",
  data() {
    return {
      loading: true,
      isMobile: true,
      filter: false,
      paramModalDate: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "1 dia",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "3 dias",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "7 dias",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "30 dias",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "90 dias",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "180 dias",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      resultado: false,
    };
  },
  props: { Type: Number, Label: String, isFilter: Boolean },
  watch: {
    filter(newVal, oldVal) {
      this.paramModalDate = false;
      this.resultado = false;
    },
  },
  methods: {
    gerarRelatorio() {
      this.loading = false;
      let date_1 = new Date(this.paramModalDate[1]);
      let date_2 = new Date(this.paramModalDate[0]);
      let difference = date_1.getTime() - date_2.getTime();
      let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
      if (TotalDays > 180) {
        this.$onpoint.errorModal(
          "Não é possível filtrar um período maior que 6 meses."
        );
        this.paramModalDate = false;
        this.loading = true;
        return;
      }
      return this.$onpoint.loading(() => {
        let data = {
          Since: this.paramModalDate[0],
          Until: this.paramModalDate[1],
        };
        let api = "";
        if (this.Type == 1) {
          api = "/api/PolicyHolder/PolicyHolderExcelReport";
        } else {
          api = "/api/Broker/BrokerExcelReport";
        }
        return axiosInstance({
          method: "post",
          url: api,
          data: data
        })
          .then((response) => {
            this.resultado = response.data;
            let url = response.data.Response.Link
            const link = document.getElementById("btn");
            link.href = url;
            const nome =
              "Relatório_" +
              this.Label +
              "_" +
              this.paramModalDate[0] +
              "_até_" +
              this.paramModalDate[1] +
              ".xlsx";
            link.setAttribute("download", nome);
            this.loading = true;
          })
          .catch((err) => {
            this.$onpoint.errorModal(err.response.data.Errors);
            this.loading = true;
          });
      });
    },
    checkDevice() {
      if (window.innerWidth > 576) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
    log(event) {},
  },
  created() {
    this.checkDevice();
  },
  mounted() {
    window.addEventListener("resize", this.checkDevice);
  },
};
</script>

<style lang="scss">
#filtro-open {
  .vs-popup {
    min-width: 60%;
    max-width: 90%;
  }
  .vs-popup--title h3 {
    font-weight: 700 !important;
  }

  .container-element {
    position: relative;

    .label-content {
      font-size: 0.85rem;
      color: rgba(0, 0, 0, 0.6);
      padding-left: 5px;
    }
    .label {
      transform: translateY(-95%) scale(0.9);
      transform-origin: top-left;
      position: absolute;
    }
  }

  h5.title-group {
    font-size: 18px;
    color: #a1a1a1;
    font-weight: 500;
    width: 100%;
    padding: 10px 0.5rem 0;
  }
}

.filtro {
  display: flex;
  .data {
    width: 70%;
  }
  .tipo {
    width: 30%;
    padding-left: 15px;
  }
  .resultado #btn {
    background: rgba(var(--vs-primary), 1) !important;
    color: white !important;
    padding: 10px 15px;
    border-radius: 20px;
    margin-left: 5px;
  }
} 
</style>
